import React, { useState } from 'react';
import useChatStore from '../store/chat';
import useAuthStore from '../store/auth';
import Conversation from './chats/comversation/Conversation';
import Sidebar from './chats/sidebar/Sidebar';
import Modal from './modal/Modal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addChat, getPhonebook } from '../lib/api';
import UserGroupList from './chats/contacts/UserGroupList';
import ContactList from './chats/contacts/ContactList';

const Chat = ({ onSendMessage, onMinimizeChat, chats, isLoadingChats }) => {
  const {
    chat: selectedChat,
    setChat,
    setSeeners,
  } = useChatStore((state) => state);
  const { auth } = useAuthStore((state) => state);
  const [toggleAddChat, setToggleAddChat] = useState(false);

  const queryClient = useQueryClient();

  const {
    data: contacts,
    isFetching,
    isPending,
    refetch: refetchContacts,
  } = useQuery({
    queryKey: ['contacts'],
    queryFn: async () => getPhonebook(auth._id),
    enabled: false,
  });

  const { mutate: mutateAddChat } = useMutation({
    mutationFn: addChat,
    onSuccess: (data) => {
      const newChat = {
        chat: data,
        lastMessage: '',
        unreadedMessagesCount: 0,
      };

      queryClient.setQueryData(['chats'], (oldChats) => {
        return oldChats ? [newChat, ...oldChats] : [newChat];
      });

      setToggleAddChat(false);
      setChat(data);
    },
    onError: (err) => {
      console.log('[script] Error add chat: ', err);
    },
  });

  const handleSelectedChat = (chat) => {
    const allSeeners = chat.users
      .filter((usr) => usr.id !== auth._id)
      .map((usr) => usr.id);

    setSeeners(allSeeners);
    setChat(chat);
  };

  const handleNewChat = () => {
    setToggleAddChat(true);
    refetchContacts();
  };

  const handleAddChat = (userId) => {
    let found = false;
    const allChats = queryClient.getQueryData(['chats']);
    if (allChats) {
      for (const chat of allChats) {
        let chatUsers = chat.chat.users;

        chatUsers = chatUsers.filter(
          ({ id }) => id !== userId && id !== auth._id
        );

        console.log(chatUsers);
        if (!chatUsers.length) {
          console.log('nasao chat');
          setToggleAddChat(false);
          setChat(chat.chat);
          found = true;
          break;
        }
      }
    }

    if (!found) {
      createNewChat(userId);
    }
  };

  const createNewChat = (userId) => {
    const body = {
      participantIds: [userId],
    };

    mutateAddChat(body);
  };

  return (
    <section id="section-chat" className="chat-section">
      <div className="chat-container" id="container-chat">
        {/* <aside className="chat-sidebar">
          <a href="#" className="chat-sidebar-logo">
            <i className="ri-chat-1-fill"></i>
          </a>
          <ul className="chat-sidebar-menu">
            <li className="active">
              <a href="#" data-title="Chats">
                <i className="ri-chat-3-line"></i>
              </a>
            </li>
            <li>
              <a href="#" data-title="Contacts">
                <i className="ri-contacts-line"></i>
              </a>
            </li>
            <li>
              <a href="#" data-title="Documents">
                <i className="ri-folder-line"></i>
              </a>
            </li>
            <li>
              <a href="#" data-title="Settings">
                <i className="ri-settings-line"></i>
              </a>
            </li>
            <li className="chat-sidebar-profile">
              <button type="button" className="chat-sidebar-profile-toggle">
                <img
                  src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cGVvcGxlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                  alt=""
                />
              </button>
              <ul className="chat-sidebar-profile-dropdown">
                <li>
                  <a href="#">
                    <i className="ri-user-line"></i> Profile
                  </a>
                </li>
                <li>
                  <a href="#" id="button_logout">
                    <i className="ri-logout-box-line"></i> Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </aside> */}

        <div className="chat-content">
          <Sidebar
            chats={chats}
            onSelectedChat={handleSelectedChat}
            onNewChat={handleNewChat}
            onMinimizeChat={onMinimizeChat}
            isLoadingChats={isLoadingChats}
          />

          {/* if no selected chat, show this */}
          {!selectedChat ? (
            <div
              className="conversation conversation-default active"
              id="conversation-default"
            >
              <i className="ri-chat-3-line"></i>
              <p>Select chat and view conversation!</p>
            </div>
          ) : (
            <Conversation />
          )}
        </div>
      </div>

      {toggleAddChat && (
        <Modal
          title="Contacts"
          onClose={() => setToggleAddChat((prev) => !prev)}
        >
          <div className="modal-add-chat-content">
            {isFetching && isPending ? (
              <p>Loading contacts...</p>
            ) : (
              <div
                className="content-messages"
                id="modal-add-chat-contacts-holder"
              >
                {!!contacts?.admins?.length && (
                  <ContactList
                    users={contacts.admins}
                    handleAddChat={handleAddChat}
                    title="Support"
                  />
                )}
                {contacts?.groups?.length ? (
                  <UserGroupList
                    contactGroups={contacts.groups}
                    handleAddChat={handleAddChat}
                  />
                ) : (
                  <p>No groups</p>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
    </section>
  );
};

export default Chat;
