export function broadcastMobile(command, data) {
  switch (command) {
    case 'authFinished':
      try {
        window.mobileClient.onAuthFinished(JSON.stringify(data));
      } catch (error) {}
      try {
        window.webkit.messageHandlers.mobileClient.postMessage(
          JSON.stringify({ command, data })
        );
      } catch (error) {}
      break;
    case 'finishedLoading':
      try {
        window.mobileClient.onFinishedLoading();
      } catch (error) {}
      try {
        window.webkit.messageHandlers.mobileClient.postMessage(
          JSON.stringify({ command })
        );
      } catch (error) {}
      break;
    case 'deviceSubscribed':
      try {
        window.mobileClient.onDeviceSubscribed();
      } catch (error) {}
      try {
        window.webkit.messageHandlers.mobileClient.postMessage(
          JSON.stringify({ command })
        );
      } catch (error) {}
      break;
    case 'closeChat':
      try {
        window.mobileClient.onCloseChat();
      } catch (error) {}
      try {
        window.webkit.messageHandlers.mobileClient.postMessage(
          JSON.stringify({ command })
        );
      } catch (error) {}
      break;
    case 'getCurrentScreen':
      try {
        window.mobileClient.onCloseChat();
      } catch (error) {}
      try {
        window.webkit.messageHandlers.mobileClient.postMessage(
          JSON.stringify({ command, data })
        );
      } catch (error) {}
      break;
    default:
      break;
  }
}
