import React, { useMemo } from 'react';

const ContactItem = ({ user, handleAddChat }) => {
  const isAdmin = user.userGroup.isAdmin;

  return (
    <div className="contact" onClick={() => handleAddChat(user.id)}>
      <img
        className="content-message-image"
        src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cGVvcGxlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
        alt=""
      />
      <span className="content-message-info">
        <span className="content-message-name">{user.name}</span>
        <span className="content-message-text">{user.email}</span>
      </span>
      {isAdmin && (
        <div className='badge'>admin</div>
      )}
    </div>
  );
};

export default ContactItem;
