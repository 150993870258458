const formatLastOnlineDateTime = (time) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
  return time.toLocaleString('en-US', options);
};

const formatLastOnlineTime = (time) => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
  return time.toLocaleTimeString('en-US', options);
};

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const updateTimeDifference = (lastTimeOnline) => {
  const currentTime = new Date();
  const lastOnlineTime = new Date(lastTimeOnline);
  const timeDifference = (currentTime - lastOnlineTime) / 1000; // Convert to seconds
  console.log('time difference ', timeDifference)
  if (timeDifference < 60) {
    return 'Online';
  } else if (timeDifference < 180) {
    return ' 3 minutes ago';
  } else if (timeDifference >= 180) {
    // 1 hour in seconds
    if (isToday(lastOnlineTime)) {
      return `last seen today at ${formatLastOnlineTime(lastOnlineTime)}`;
    } else {
      return `last seen: ${formatLastOnlineDateTime(lastOnlineTime)}`;
    }
  } else {
    return `last seen: ${formatLastOnlineDateTime(lastOnlineTime)}`;
  }
};

export const getTimeDifference = (lastTimeOnline) => {
  if (!lastTimeOnline) return 0;

  const currentTime = new Date();
  const lastOnlineTime = new Date(lastTimeOnline);
  const timeDifference = (currentTime - lastOnlineTime) / 1000; // Convert to seconds

  return timeDifference;
};