import { create } from 'zustand';

const AUTH_DATA = 'brrmChat_auth';

const getAuth = () => {
  const auth =
    JSON.parse(localStorage.getItem(AUTH_DATA)) ||
    JSON.parse(sessionStorage.getItem(AUTH_DATA)) ||
    null;

  return {
    token: auth?.token || null,
    _id: auth?._id || null,
  };
};

const isLoggedIn =
  !!localStorage.getItem(AUTH_DATA) || !!sessionStorage.getItem(AUTH_DATA);

const useAuthStore = create((set, get) => ({
  auth: getAuth(),
  isLoggedIn,
  loggedInUser: null,
  platform: 'web',
  socket: null,
  deviceUniqueId: null,
  setUser: (data) =>
    set(() => ({
      loggedInUser: data,
    })),
  loginUser: ({ data, auth, platform }) =>
    set(() => ({
      isLoggedIn: true,
      loggedInUser: data.user,
      auth,
      platform,
    })),
  logoutUser: () =>
    set({
      auth: {
        token: null,
        _id: null,
      },
      isLoggedIn: false,
      loggedInUser: null,
    }),
  setSocket: (socket) =>
    set(() => ({
      socket,
    })),
  setDeviceId: (deviceUniqueId) =>
    set(() => ({
      deviceUniqueId,
    })),
  disconnectSocket: () => {
    const { socket } = get();
    if (socket) {
      console.log(socket)
      socket.disconnect();
      set({ socket: null });
    }
  },
}));

export default useAuthStore;
