import React, { useMemo } from 'react';
import useAuthStore from '../../../store/auth';
import ContactItem from './ContactItem';

const ContactList = ({ users, title, handleAddChat }) => {
  const { auth } = useAuthStore((state) => state);

  const filteredUsers = useMemo(() => {
    return users.length
      ? users
          .filter((usr) => usr.id !== auth._id)
          .sort((a, b) => b.userGroup.isAdmin - a.userGroup.isAdmin)
      : [];
  }, [auth._id, users]);

  return (
    <>
      <div className="content-message-title">
        <span>{title}</span>
      </div>
      {filteredUsers.length ? (
        <ul className="content-messages-list">
          {filteredUsers.map((user) => (
            <ContactItem user={user} handleAddChat={handleAddChat} key={user.id} />
          ))}
        </ul>
      ) : (
        <div className="content-message-title">No users in group.</div>
      )}
    </>
  );
};

export default ContactList;
