import React from 'react';
import { MoonLoader } from 'react-spinners';

const LoadingSpinner = ({ style = { transform: 'translateY(-60px)' } }) => {
  return (
    <div className="flex-center" style={style}>
      <MoonLoader
        color="#555"
        loading={true}
        size={22}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default LoadingSpinner;
