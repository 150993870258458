import React from 'react';
import ContactList from './ContactList';

const UserGroupList = ({ contactGroups, handleAddChat }) => {
  return (
    <>
      {contactGroups.map((userGroup, index) => {
        return (
          <ContactList
            users={userGroup.users}
            title={userGroup.title}
            handleAddChat={handleAddChat}
            key={index}
          />
        );
      })}
    </>
  );
};

export default UserGroupList;
