import React, { useEffect, useMemo } from 'react';
import image from '../../../assets/img/profile_default.jpg';
import { useInView } from 'react-intersection-observer';
import useChatStore from '../../../store/chat';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendSeenStatusMessage } from '../../../lib/api';
import useAuthStore from '../../../store/auth';

const IncomingMessage = ({ message, isSeen, sentAt, onSeenMessage }) => {
  const { addMessageToSeen, chatId } = useChatStore((state) => state);
  const { socket } = useAuthStore((state) => state);
  const queryClient = useQueryClient();

  // const markAsSeenMutation = useMutation(markMessageAsSeen);
  const [ref, inView] = useInView({
    root: document.getElementById(`conversation-wrapper-ul-wrapper-${chatId}`),
    threshold: 0.5,
    triggerOnce: true,
  });

  const { mutate: mutateSeenMessage } = useMutation({
    mutationFn: sendSeenStatusMessage,
    onSuccess: (data) => {
      console.log('SEENOVAO JE ', data);
      queryClient.setQueryData(['chats'], (oldChats) => {
        return oldChats.map((chat) => {
          if (chat.chatId === chatId && chat.unreadedMessagesCount > 0) {
            return {
              ...chat,
              unreadedMessagesCount: chat.unreadedMessagesCount - 1,
            };
          }
          return chat;
        });

        // return oldChats ? [newChat, ...oldChats] : [newChat];
      });

      onSeenMessage(message.id);
    },
    onError: (err) => {
      console.log('[script] Error seenMessage: ', err);
    },
  });

  // console.log('RENDER: INCOMING MESSAGE');

  // Maintain a set of message IDs that should be marked as seen
  const messagesToMarkAsSeen = useMemo(() => {
    return new Set();
  }, []);

  React.useEffect(() => {
    // console.log('USE EFEKTIC');
    if (inView && !isSeen) {
      // console.log('in view');
      // messagesToMarkAsSeen.add(message.id);
      if (socket) {
        // console.log('SALJEM SOKETOM');

        // ! umesto timeout treba nesto drugo da se smisli,
        // ! posto iz nekog razloga prvo stigne socket poruka za seen,
        // ! pa se tek onda izvrsi setNewMessages, i zbog toga nece da se promeni seen
        // setTimeout(() => {
        socket.emit(
          'chat.message.seen',
          {
            messageId: message.id,
          },
          (data) => {
            console.log(
              'seenovao je preko soketa poruku: ' + message.id + ' ',
              data
            );

            queryClient.setQueryData(['chats'], (oldChats) =>
              oldChats.map((chat) =>
                chat.chatId === chatId && chat.unreadedMessagesCount > 0
                  ? {
                      ...chat,
                      unreadedMessagesCount: chat.unreadedMessagesCount - 1,
                    }
                  : { ...chat }
              )
            );

            onSeenMessage(message.id);
          }
        );
        // }, 10);
      } else {
        mutateSeenMessage({ chatId, messageId: message.id });
      }
    }
  }, [inView, message.id, isSeen, mutateSeenMessage, chatId]);

  // if (!isSeen) {
  //   addMessageToSeen(message.id);
  // }

  let filesHtml = '';
  let audioFile = null;

  if (message.files) {
    message.files.forEach((messageFile) => {
      if (messageFile.type === 'audio' && messageFile.path) {
        audioFile = messageFile.path;
      }
    });
  }

  const handleAudioError = (e) => {
    console.error('Audio playback error detected');
    console.log(e);
    // Handle the error here
    // For example, you can load an alternative audio file, show an error message, etc.
  };

  return (
    <li ref={ref} className="conversation-item">
      <div className="conversation-item-side">
        <img className="conversation-item-image" src={image} alt="" />
      </div>
      <div className="conversation-item-content">
        <div className="conversation-item-wrapper">
          <div className="conversation-item-box">
            <div className="conversation-item-text">
              {audioFile && (
                <p>
                  <audio
                    src={audioFile}
                    controls
                    style={{ width: '210px' }}
                    onError={handleAudioError}
                  />
                </p>
              )}
              {message.message && <p>{message.message}</p>}
              <div className="conversation-item-time">{sentAt}</div>
            </div>
            {/* <div className="conversation-item-dropdown">
              <button
                type="button"
                className="conversation-item-dropdown-toggle"
                // onClick=conversationItemDropDown(this)
              >
                <i className="ri-more-2-line"></i>
              </button>
              <ul className="conversation-item-dropdown-list">
                <li>
                  <a href="#">
                    <i className="ri-share-forward-line"></i> Forward
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </li>
  );
};

export default IncomingMessage;
