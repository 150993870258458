import axios from '../api/axios';
import useAuthStore from '../store/auth';

export function jwtInterceptor() {
  axios.interceptors.request.use((request) => {
    const { auth } = useAuthStore.getState();

    // remove error if any
    // store.dispatch(errorActions.removeError())

    // add auth header with jwt if account is logged in and request is to the api url
    const token = auth.token;
    // const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
    if (token && request.url !== '/appAuth') {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  });
}
