import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ButtonWidget = ({ showChat, unreadedMessages }) => {
  return (
    <section
      id="widget"
      style={{ position: 'absolute', bottom: '0px', right: '0px' }}
    >
      <div id="show-chat" className="chat-button" onClick={showChat}>
        <span style={{ fontSize: '1.5rem' }}>
          <FontAwesomeIcon icon={faMessage} />
        </span>
        {unreadedMessages > 0 && <span className='unreaded-messages-counter'>{unreadedMessages}</span>}
      </div>
    </section>
  );
};

export default ButtonWidget;
