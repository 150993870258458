import React, { useMemo, useState } from 'react';
import ChatList from '../ChatList';
import useAuthStore from '../../../store/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faPlus,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({
  onMinimizeChat,
  chats,
  onSelectedChat,
  onNewChat,
  isLoadingChats,
}) => {
  const { platform, auth } = useAuthStore((state) => state);

  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredChats = useMemo(() => {
    return chats?.filter((chat) => {
      let headline = chat.chat.headline?.toLowerCase() ?? '';

      if (!headline) {
        chat.chat.users?.forEach((user) => {
          if (user.id !== auth._id) {
            headline += user.name;
            headline += ' ';
          }
        });
      }

      return headline.includes(searchTerm.toLowerCase());
    });
  }, [auth._id, chats, searchTerm]);

  return (
    <div className="content-sidebar">
      <div className="content-sidebar-title">
        {/* <span>Chats</span> */}
        <div style={{ display: 'flex', gap: '12px' }}>
          <button
            className="button-add-chat"
            id="button-add-chat"
            onClick={onNewChat}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>

          <button className="close-btn" id="close-btn" onClick={onMinimizeChat}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
      </div>
      {isLoadingChats ? (
        <p className="text-center">loading chats...</p>
      ) : (
        <div
          style={{
            height: '100',
            overflow: 'hidden',
          }}
        >
          <form action="" className="content-sidebar-form">
            <input
              type="text"
              autoComplete="off"
              className="content-sidebar-input"
              placeholder="Search chats..."
              value={searchTerm}
              onChange={handleInputChange}
            />
            <button className="content-sidebar-submit">
              <i className="ri-search-line"></i>
            </button>
          </form>
          <div className="content-messages">
            <div className="content-message-title">
              <span>Recently</span>
            </div>
            <ChatList chats={filteredChats} onSelectedChat={onSelectedChat} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
