import React, { useMemo } from 'react';
import useAuthStore from '../../store/auth';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

const ChatItem = ({ data, onSelectedChat }) => {
  const { chat, lastMessage, unreadedMessagesCount } = data;
  const { auth } = useAuthStore((state) => state);

  const arrayContainsArray = (arr, target) =>
    target.every((v) => arr.includes(v));
  // console.log('ITEM', data);

  let headline = chat.headline || '';

  if (!headline) {
    chat.users?.forEach((user) => {
      if (user.id !== auth._id) {
        headline += user.name;
        headline += ' ';
      }
    });
  }

  const haveUnreadMessages = !!(
    unreadedMessagesCount && unreadedMessagesCount > 0
  );

  // TODO
  let createdAtString = '';
  if (lastMessage) {
    // Parse the ISO 8601 timestamp
    const parsedTimestamp = DateTime.fromISO(lastMessage.createdAt);

    // Convert to local time
    const localTime = parsedTimestamp.toLocal();

    // Format the time as "HH:mm"
    createdAtString = localTime.toFormat('HH:mm');
  }

  let message = '';
  if (lastMessage) {
    if (lastMessage.files.length) {
      lastMessage.files.forEach((messageFile) => {
        if (messageFile.type === 'audio' && messageFile.path) {
          message = (
            <FontAwesomeIcon icon={faMicrophone} style={{ color: '#44b144' }} />
          );
        }
      });
    } else {
      message = lastMessage?.message.slice(0, 20);
    }
  }

  const isMe = useMemo(() => {
    return auth._id === lastMessage?.senderId;
  }, [auth._id, lastMessage?.senderId]);

  const isSeen = useMemo(() => {
    let seen = false;

    if (lastMessage?.seenByIds?.length) {
      if (isMe) {
        const allSeeners = chat.users
          .filter((usr) => usr.id !== auth._id)
          .map((usr) => usr.id);

        if (
          Array.isArray(lastMessage.seenByIds) &&
          arrayContainsArray(lastMessage.seenByIds, allSeeners)
        ) {
          seen = true;
        }
      }
    }

    return seen;
  }, [auth._id, chat.users, isMe, lastMessage?.seenByIds]);

  // proveriti da li je senderId === loggedInUserId, ako jeste znaci onda treba da se vide stiklice
  // ako nije, onda se ne vide stiklice, ili se vidi broj novih poruka

  let seenHtml = '';

  if (isSeen) {
    seenHtml = (
      <div className="conversation-item-status seen" style={{marginTop: 0}} id="seen-${message.id}">
        <i className="ri-check-double-line" style={{ fontSize: '1.3rem' }}></i>
      </div>
    );
  } else {
    seenHtml = (
      <div className="conversation-item-status"  style={{marginTop: 0}} id="seen-${message.id}">
        <i className="ri-check-double-line" style={{ fontSize: '1.3rem' }}></i>
      </div>
    );
  }

  let content = '';

  if (isMe) {
    content = seenHtml;
  } else {
    content = haveUnreadMessages ? (
      <span className="content-message-unread">
        {unreadedMessagesCount < 100 ? unreadedMessagesCount : '99+'}
      </span>
    ) : (
      ''
    );
  }

  return (
    <li onClick={() => onSelectedChat(chat)}>
      <a href="#" data-conversation="#conversation-1">
        <img
          className="content-message-image"
          src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cGVvcGxlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
          alt=""
        />
        <span className="content-message-info">
          <span className="content-message-name">{headline}</span>
          <span className="content-message-text">{message}</span>
        </span>
        <div className="content-message-more">
          {/* {haveUnreadMessages && (
            <span className="content-message-unread">
              {unreadedMessagesCount < 100 ? unreadedMessagesCount : '99+'}
            </span>
          )} */}
          {content}
          <span className="content-message-time">{createdAtString}</span>
        </div>
      </a>
    </li>
  );
};

export default ChatItem;
