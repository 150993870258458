import { create } from 'zustand';
import useAuthStore from './auth';

const useChatStore = create((set) => ({
  chat: null,
  chatId: null,
  allSeeners: [],
  messagesToSeen: [],
  notifications: [],
  allUnreadMessages: 0,
  isTyping: false,
  setChat: (chat) =>
    set(() => ({
      chat,
      chatId: chat?.id,
    })),
  setSeeners: (seeners) =>
    set(() => ({
      allSeeners: seeners,
    })),
  setUnreadMessages: (number) =>
    set(() => ({
      allUnreadMessages: number,
    })),
  addMessageToSeen: (message) =>
    set((state) => ({
      messagesToSeen: [...state.messagesToSeen, message],
    })),
  setNewNotification: (notification) => {
    // const { auth } = useAuthStore.getState();
    // const message = JSON.parse(notification.message);
    // console.log(notification)
    // console.log(auth)
    // if (message.senderId === auth?._id || message.userId === auth?._id) {
    //   console.log('ne obradjuj ', message)
    //   return;
    // }
    // console.log('moze')
    set((state) => ({
      notifications: [notification, ...state.notifications],
    }));
  },
  removeNotification: () =>
    set((state) => ({
      notifications: [...state.notifications.slice(0, -1)],
    })),
  setTyping: (isTyping) =>
    set(() => ({
      isTyping,
    })),
}));

export default useChatStore;
