import React from 'react';
import ChatItem from './ChatItem';

const ChatList = ({ chats, onSelectedChat }) => {
  return (
    <ul className="content-messages-list" id="ulChatsHolder">
      {chats.map((chat) => (
        <ChatItem data={chat} key={chat.chatId} onSelectedChat={onSelectedChat} />
      ))}
    </ul>
  );
};

export default ChatList;
