import React from 'react';
import image from '../../../assets/img/profile_default.jpg';

const OutcomingMessage = ({ message, isSeen, sentAt }) => {
  // console.log('RENDER: MESSAGE ITEM');

  let seenHtml = '';

  if (isSeen) {
    seenHtml = (
      <div className="conversation-item-status seen" id="seen-${message.id}">
        Seen <i className="ri-check-double-line"></i>
      </div>
    );
  } else {
    seenHtml = (
      <div className="conversation-item-status" id="seen-${message.id}">
        Sent <i className="ri-check-double-line"></i>
      </div>
    );
  }

  let filesHtml = '';
  let audioFile = null;

  function getDuration(src) {
    return new Promise(function (resolve) {
      var audio = new Audio();

      audio.addEventListener('loadedmetadata', function () {
        resolve(audio.duration);
      });

      audio.src = src;
    });
  }

  if (message.files) {
    message.files.forEach((messageFile) => {
      if (messageFile.type === 'audio' && messageFile.path) {
        audioFile = messageFile.path;
        // getDuration(audioFile).then(function (length) {
        //   console.log('I got length ' + length);
        //   // document.getElementById('duration').textContent = length;
        // });
        // var mp3file = audioFile;

        // // Create an instance of AudioContext
        // var audioContext = new (window.AudioContext ||
        //   window.webkitAudioContext)();

        // // Make an Http Request
        // var request = new XMLHttpRequest();
        // request.open('GET', mp3file, true);
        // request.responseType = 'arraybuffer';
        // request.onload = function () {
        //   audioContext.decodeAudioData(request.response, function (buffer) {
        //     // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
        //     var duration = buffer.duration;

        //     // example 12.3234 seconds
        //     console.log(
        //       'The duration of the song is of: ' + duration + ' seconds'
        //     );
        //     // Alternatively, just display the integer value with
        //     // parseInt(duration)
        //     // 12 seconds
        //   });
        // };

        // // Start Request
        // request.send();
      }
    });
  }

  const handleLoadedMetadata = (e) => {
    console.log(e);
    // const durationInSeconds = audioElement.duration;
    // const durationFormatted = formatDuration(durationInSeconds);
    // console.log('Audio duration:', durationFormatted);
  };

  // Function to format the duration as HH:MM:SS if needed
  function formatDuration(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const formattedDuration = `${hours}:${String(minutes).padStart(
      2,
      '0'
    )}:${String(seconds).padStart(2, '0')}`;
    return formattedDuration;
  }

  const handleAudioError = (e) => {
    console.error('Audio playback error detected');
    console.log(e)
    // Handle the error here
    // For example, you can load an alternative audio file, show an error message, etc.
  };

  return (
    <li className="conversation-item me">
      <div className="conversation-item-side">
        <img className="conversation-item-image" src={image} alt="" />
      </div>
      <div className="conversation-item-content">
        <div className="conversation-item-wrapper">
          <div className="conversation-item-box">
            <div className="conversation-item-text">
              {audioFile && (
                <p>
                  <audio
                    src={audioFile}
                    style={{ width: '210px' }}
                    controls
                    onLoadedMetadata={handleLoadedMetadata}
                    onError={handleAudioError}
                  />
                </p>
              )}
              {message.message && <p>{message.message}</p>}
              <div className="conversation-item-time-container">
                <div className="conversation-item-time">{sentAt}</div>
                {seenHtml}
              </div>
            </div>
            {/* <div className="conversation-item-dropdown">
              <button
                type="button"
                className="conversation-item-dropdown-toggle"
                // onClick=conversationItemDropDown(this)
              >
                <i className="ri-more-2-line"></i>
              </button>
              <ul className="conversation-item-dropdown-list">
                <li>
                  <a href="#">
                    <i className="ri-share-forward-line"></i> Forward
                  </a>
                </li>
                {deleteHtml}
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </li>
  );
};

export default OutcomingMessage;
