const defaultStyle = {
  border: ' 0px',
  position: 'fixed',
  bottom: '0px',
  right: '0px',
  left: 'auto',
  zIndex: '16777000',
  margin: '0px',
  padding: '0px',
  width: '385px',
  height: '625px',
  maxWidth: '100%',
  maxHeight: ' 100%',
  visibility: 'visible',
  boxShadow: '0px 0px 6px 0px #c9c9c9',
  borderRadius: '10px',
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
};

export default defaultStyle;
