import React from 'react';
import MessageItem from './MessageItem';

const MessagesList = ({ messages, onSeenMessage }) => {
  return (
    <ul className="conversation-wrapper" id="conversation-wrapper-ul">
      {messages
        .slice()
        .reverse()
        .map((message) => (
          <MessageItem
            message={message}
            key={message.id}
            onSeenMessage={onSeenMessage}
          />
        ))}
    </ul>
  );
};

export default MessagesList;
