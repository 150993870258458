import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { authAppAccessToken, getAllChats, subscribeDevice } from '../lib/api';
import SplashScreen from '../components/SplashScreen';
import ButtonWidget from '../components/ButtonWidget';
import useAuthStore from '../store/auth';
import Chat from '../components/Chat';
import useChatStore from '../store/chat';
import SocketManager from '../helpers/SocketManager';
import { broadcastMobile } from '../helpers/utils/mobile-broadcast';
import { NotificationEventType } from '../helpers/consts/events';
import defaultStyle from '../helpers/consts/style';
import { unstable_batchedUpdates } from 'react-dom'; // or 'react-native'

const WEB_PLATFORM = 'web';

const LandingPage = () => {
  const queryClient = useQueryClient();

  const platform = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const platformParam = params.get('platform');

    return platformParam || 'web';
  }, []);

  const [showChat, setShowChat] = useState(() => {
    return platform !== WEB_PLATFORM;
  });
  const [buttonWidget, setButtonWidget] = useState(() => {
    return platform === WEB_PLATFORM;
  });
  const [choosenChatId, setChoosenChatId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [queue, setQueue] = useState([]);

  const {
    auth,
    loginUser,
    isLoggedIn,
    socket,
    setDeviceId,
    disconnectSocket,
    logoutUser,
  } = useAuthStore((state) => state);
  const {
    notifications,
    setNewNotification,
    chatId,
    setUnreadMessages,
    allUnreadMessages,
    setChat,
  } = useChatStore((state) => state);

  let selectedChatId = chatId;

  const {
    isPending: isLoadingAuth,
    isError,
    mutate: mutateAuth,
  } = useMutation({
    mutationFn: authAppAccessToken,
    onSuccess: (data) => {
      const auth = {
        token: data.token,
        _id: data.user.id,
      };

      loginUser({ data, auth, platform });
      getChats();
      broadcastMobile('authFinished', { id: data.user.id, token: data.token });

      window.parent.postMessage(
        {
          type: 'onReady',
        },
        '*'
      );
    },
    onError: (err) => {},
  });

  const { mutate: mutateSubscribe } = useMutation({
    mutationFn: subscribeDevice,
    onSuccess: (data) => {
      console.log(
        '[script] subscribeDebice: device subscribed and registered for push nitifications.'
      );
      broadcastMobile('deviceSubscribed', {});
    },
  });

  const {
    data: chats,
    refetch: getChats,
    isLoading: isLoadingChats,
    status,
  } = useQuery({
    queryKey: ['chats'],
    queryFn: getAllChats,
    enabled: false,
  });

  useEffect(() => {
    console.log('totaaaal');
    if (chats?.length) {
      const totalUnreadMessages = chats.reduce(
        (total, chat) => total + chat.unreadedMessagesCount,
        0
      );

      console.log(totalUnreadMessages);

      setUnreadMessages(totalUnreadMessages);
    }
  }, [chats, setUnreadMessages]);

  useEffect(() => {
    console.log(status);
    if (status === 'success' && chats?.length) {
      console.log('GET FINISHED');

      const selectedChat = chats.find((chat) => chat.chatId === choosenChatId);

      if (selectedChat) {
        setChat(selectedChat.chat);
      }

      setChoosenChatId(null);
    }
  }, [status, chats, choosenChatId, setChat]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    window.BrrmChat = BrrmChat;
  }, []);

  useEffect(() => {
    console.log('lista notifikacija pre obrade: ', messages);
    if (!messages.length) return;

    // console.log(messages)
    const notification = messages[messages.length - 1];

    const message = JSON.parse(notification.message);
    console.log(notification);
    // console.log(auth)
    // ako je senderId, tj osoba koja je poslala poruku === ulogovanom user, odbij notifikaciju
    // ili ako je userId, tj user koji je seenovao poruku === ulogovanom useru, odbij isto jer je ta notifikacija namenjena drugima, a ja ne meni jer sam ja video poruku
    if (message.senderId === auth?._id || message.userId === auth?._id) {
      console.log('ne obradjuj ', message);
      setMessages((oldMessages) => [...oldMessages.slice(0, -1)]);
      return;
    }
    processNotification(notification);
    setMessages((oldMessages) => [...oldMessages.slice(0, -1)]);
  }, [auth, messages]);

  useEffect(() => {
    console.log('NOTIFIKACIJE koje su prosle ', notifications);
  }, [notifications]);

  useEffect(() => {
    if (!queue.length) return;

    if (auth.token) {
      const queueFunction = queue[queue.length - 1];

      queueFunction();

      setQueue((prev) => [...prev.slice(0, -1)]);
    }
  }, [auth.token, queue]);

  useEffect(() => {
    const onLoad = () => {
      broadcastMobile('finishedLoading', {});
    };

    document.addEventListener('DOMContentLoaded', onLoad);

    // If the event has already fired, execute the function immediately
    if (
      document.readyState === 'interactive' ||
      document.readyState === 'complete'
    ) {
      onLoad();
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', onLoad);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // Handle incoming messages
      socket.on('chat.message', (message) => {
        // Invoke the callback when a new message is received
        console.log('SOCKET ', message);
        // processNotification(message);
        setMessages((oldMessages) => [message, ...oldMessages]);
      });

      socket.on('chat.message.seen', (message) => {
        // Invoke the callback when a new message is received
        console.log('SOCKET ', message);
        // processNotification(message);
        setMessages((oldMessages) => [message, ...oldMessages]);
      });
    }
  }, [socket]);

  const handleMessage = (event) => {
    console.log(event.origin);

    // Check the origin of the message to ensure it's coming from a trusted source
    // if (event.origin !== 'https://your-widget-origin.com') {
    //   return;
    // }

    const data = event.data;

    if (data.command) {
      BrrmChat(data.command, data.args);
    }
  };

  const processNotification = (data) => {
    console.log(selectedChatId);
    console.log(data?.chatId);

    if (!data) return;
    const chats = queryClient.getQueryData(['chats']);
    // setMessages(data);

    if (data.eventName === NotificationEventType.NEW_MESSAGE) {
      // ukoliko je otvoren chat za koga je stigla notifikacija za novu poruku
      // dodaj u listu notifikacija, kako bi mogla da se ishendla unutar chata

      // console.log(chat);
      // console.log(selectedChatId);
      // console.log(data.chatId);
      if (selectedChatId === data?.chatId) {
        console.log('stigla nova poruka');
        setNewNotification(data);
      }

      // TODO: ukoliko ne postoji chat jos uvek a stigne notifikacija za njega, getuj chat po ID
      if (
        !chats?.length ||
        !chats?.some(({ chatId }) => chatId === data.chatId)
      ) {
        // da li moze da se desi da npr ne postoji taj chat jos uvek i okine se ruta za
        // getovanje po id, i pre zavrsetka rute stigne jos 1 notifikacija i ono se okine jos jednom
        // da li nekako da se ishendla kroz neki queue
        console.log('ovde');
        return getChats();
      }

      // const updatedChats = chats.map((chat) => ({
      //   ...chat,
      //   ...(chat.chatId === data.chatId && {
      //     lastMessage: JSON.parse(data.message),
      //     unreadedMessagesCount:
      //       data.chatId !== selectedChatId
      //         ? chat.unreadedMessagesCount + 1
      //         : chat.unreadedMessagesCount + 1,
      //   }),
      // }));

      const updatedChats = chats.map((chat) => {
        // let updatedChat = { ...chat };
        // in this way, it won't change original chats array. It should be done in an immutable way like this.
        // but it will do shallow copy, not deep copy, so if I want to change lets say updatedChat.lasMessage.seenByIds, it will
        // change on both updatedChats and original chats array, because shallow copy doesn't copy nested objects, its only reference them.
        // I can use here let updatedChat = JSON.parse(JSON.stringify(chat))

        let updatedChat = JSON.parse(JSON.stringify(chat));

        if (updatedChat.chatId === data.chatId) {
          updatedChat.lastMessage = JSON.parse(data.message);
          updatedChat.unreadedMessagesCount =
            data.chatId !== selectedChatId
              ? updatedChat.unreadedMessagesCount + 1
              : updatedChat.unreadedMessagesCount + 1;
        }

        if (
          updatedChat.chat.users?.length <= 2 &&
          selectedChatId !== data?.chatId
        ) {
          const userId = JSON.parse(data.message)?.senderId;
          let user = updatedChat.chat.users?.find((user) => user.id === userId);
          if (user) {
            user.lastTimeOnline = new Date().toISOString();
          }
        }

        return updatedChat;
      });

      // put chat on first plate in array
      const index = updatedChats.findIndex(
        (chat) => chat.chatId === data.chatId
      );

      if (index !== -1) {
        updatedChats.unshift(updatedChats.splice(index, 1)[0]);
      }

      console.log(updatedChats);

      queryClient.setQueryData(['chats'], updatedChats);
    } else {
      const updatedChats = chats.map((chat) => {
        if (chat.chatId !== data.chatId) return chat;

        let updatedChat = JSON.parse(JSON.stringify(chat));

        if (
          updatedChat.chat.users?.length <= 2 &&
          selectedChatId !== data?.chatId
        ) {
          const userId = JSON.parse(data.message)?.userId;

          let user = updatedChat.chat.users?.find((user) => user.id === userId);

          if (user) {
            user.lastTimeOnline = new Date().toISOString();
          }
        }

        if (data.messageId === chat?.lastMessage?.id) {
          const message = JSON.parse(data.message);

          updatedChat.lastMessage.seenByIds = [
            ...(updatedChat.lastMessage.seenByIds || []),
            message.userId,
          ];
        }

        return updatedChat;
      });

      queryClient.setQueryData(['chats'], updatedChats);

      setNewNotification(data);
    }
  };

  const showWidget = () => {
    setButtonWidget(true);

    window.parent.postMessage(
      {
        type: 'defaultStyle',
      },
      '*'
    );
  };

  const handleRemoveData = () => {
    disconnectSocket();
    setDeviceId(null);
    logoutUser();
    setMessages([]);
  };

  const handleGetCurrentScreen = () => {
    console.log(selectedChatId);
    broadcastMobile('getCurrentScreen', {
      currentScreen: chatId ? 'selected_chat' : 'chat_list',
    });
  };

  const handleOpenPage = (page) => {
    switch (page) {
      case 'chat-list':
        setChat(null);
        break;
      default:
        break;
    }
  };

  const handleOpenChat = (chatId) => {
    const chats = queryClient.getQueryData(['chats']);

    const selectedChat = chats.find((chat) => chat.chatId === chatId);

    if (selectedChat) {
      setChat(selectedChat.chat);
    }
  };

  // TODO: implement 'openChat', 'closeChat'

  const BrrmChat = (command, ...args) => {
    if (!command) {
      return 'No command provided';
    }
    switch (command) {
      case 'auth':
        console.log('[BrrmChat] auth: ', args);
        if (args.length > 0) {
          // const userPlatform = args[0]['platform'];

          // userPlatform && setPlatform(userPlatform);

          if (!args[0]['deviceUniqueId']) {
            return console.error('No deviceUniqueId object provided');
          }

          setDeviceId(args[0]['deviceUniqueId']);

          const user = args[0];

          if (!user.appAccessToken) {
            setError('Problem with initializing the chat');
            return console.error('No appAccessToken provided in auth command');
          }
          if (!user.uniqueId) {
            setError('Problem with initializing the chat');
            return console.error('No uniqueId object provided in auth command');
          }
          if (!user.email) {
            setError('Problem with initializing the chat');
            return console.error('No email object provided in auth command');
          }
          if (!user.name) {
            setError('Problem with initializing the chat');
            return console.error('No name object provided in auth command');
          }
          if (!user.groupUniqueId) {
            setError('Problem with initializing the chat');
            return console.error('No groupUniqueId provided in auth command');
          }
          if (!user.groupName) {
            setError('Problem with initializing the chat');
            return console.error('No groupName provided in auth command');
          }
          if (!user.groupName) {
            setError('Problem with initializing the chat');
            return console.error('No DEVICE ID provided in auth command');
          }
          console.log('chatId ', user.chatId);
          if (user.chatId) {
            setChoosenChatId(user.chatId);
          }

          const body = {
            appAccessToken: user.appAccessToken,
            user: {
              uniqueId: user.uniqueId,
              email: user.email,
              name: user.name,
              groupUniqueId: user.groupUniqueId,
              groupName: user.groupName,
              isGroupAdmin: !!user.isGroupAdmin,
              deviceUniqueId: user.deviceUniqueId
            },
          };

          disconnectSocket();
          mutateAuth(body);
        } else {
          return 'Bad Args';
        }
        return;
      case 'subscribe-device':
        console.log('[BrrmChat] subscribe-device: ', args);
        if (args.length > 0) {
          const data = args[0];

          if (!data.fcmToken) {
            return console.error('No fcmToken provided');
          }

          if (!data.deviceUniqueId) {
            return console.error('No deviceUniqueId object provided');
          }

          setDeviceId(data.deviceUniqueId);

          auth.token
            ? mutateSubscribe({
                fcmToken: data.fcmToken,
                uniqueId: data.deviceUniqueId,
              })
            : setQueue((prev) => [
                ...prev,
                () =>
                  mutateSubscribe({
                    fcmToken: data.fcmToken,
                    uniqueId: data.deviceUniqueId,
                  }),
              ]);
        } else {
          return 'Bad Args';
        }
        return;
      case 'notify':
        console.log('[BrrmChat] notify: ', args);
        if (args.length > 0) {
          const data = args[0];
          // const message = JSON.parse(data.message);
          // console.log(message);
          // console.log(auth);
          // // ! OVDE JE AUTH NULL, STO ?
          // if (message.senderId === auth?._id || message.userId === auth?._id) {
          //   console.log('ne obradjuj ', message);
          //   return;
          // }

          // processNotification(data);
          setMessages((oldMessages) => [data, ...oldMessages]);
        } else {
          return 'Bad Args';
        }
        return;
      case 'showWidget':
        showWidget();
        return;
      case 'removeChat':
        handleRemoveData();
        return;
      case 'get-current-screen':
        // handleGetCurrentScreen();

        const isSelectedChat = !!useChatStore.getState().chatId;
        return isSelectedChat ? 'selected_chat' : 'chat_list';
      case 'open-page':
        console.log('POZIVA SE OPEN PAGE');
        console.log(args[0]);
        if (args.length > 0) {
          const data = args[0];

          if (!data['pageName']) {
            return console.error('No page provided');
          }

          handleOpenPage(data['pageName']);
        } else {
          return 'Bad Args';
        }
        return;
      case 'open-chat':
        if (args.length > 0) {
          const data = args[0];

          if (!data['chatId']) {
            return console.error('No chatId provided');
          }

          handleOpenChat(data['chatId']);
        } else {
          return 'Bad Args';
        }
        return;
      default:
        console.error('unsuported command ', command);
        return console.error('Unsupported command');
    }
  };

  function handleShowChat() {
    window.parent.postMessage(
      {
        type: 'style',
        style: defaultStyle,
        isChatOpen: true,
      },
      '*'
    );

    setButtonWidget(false);
    setShowChat(true);
  }

  const minimizeChat = () => {
    if (platform === WEB_PLATFORM) {
      window.parent.postMessage(
        {
          type: 'defaultStyle',
          isChatOpen: false,
        },
        '*'
      );
      setButtonWidget(true);
      setShowChat(false);
    } else {
      broadcastMobile('closeChat', {});
    }
  };

  return (
    <>
      <SocketManager />
      {buttonWidget && !showChat && (
        <ButtonWidget
          showChat={handleShowChat}
          unreadedMessages={allUnreadMessages}
        />
      )}

      {!buttonWidget && showChat && (
        <>
          {!isLoggedIn ? (
            // <></>
            <SplashScreen
              isLoading={isLoadingAuth}
              onMinimizeChat={minimizeChat}
              error={error}
            />
          ) : (
            <>
              {/* ako je ulogovan, ako je web, prikazi widget button */}
              {/* kad se klikne na button, prikazi chat */}
              {/* ako jos nisu ucitani chatovi, prikazi loader */}

              <>
                <Chat
                  onMinimizeChat={minimizeChat}
                  chats={chats}
                  isLoadingChats={isLoadingChats}
                />

                {/* ako postoji error, prikazi error */}
              </>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LandingPage;
