import Login from './pages/Login';
import Register from './pages/Register';
import './style.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import LandingPage from './pages/LandingPage';
import './assets/style/tailwind-colors.css';

function App() {

  const ProtectedRoute = ({ children }) => {
    

    return children;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
