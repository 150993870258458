import React, { useEffect, useMemo, useRef, useState } from 'react';
import useChatStore from '../../../store/chat';
import useAuthStore from '../../../store/auth';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { getChatMessages, postMessage } from '../../../lib/api';
import ConversationHeader from './ConversationHeader';
import MessagesList from './MessagesList';
import { getTimeDifference, updateTimeDifference } from '../../../helpers/utils/_util';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { MoonLoader, PulseLoader } from 'react-spinners';
import AudioRecorder from '../../audio-recorder/AudioRecorder';
import { NotificationEventType } from '../../../helpers/consts/events';
import image from '../../../assets/img/profile_default.jpg';

const LIMIT = 20;

const Conversation = () => {
  const { register, handleSubmit, reset } = useForm();
  const queryClient = useQueryClient();

  const chatContainerRef = useRef(null);
  const {
    chat: selectedChat,
    chatId,
    notifications,
    removeNotification,
  } = useChatStore((state) => state);
  const typingTimeoutRef = useRef(null);

  const { auth, socket } = useAuthStore((state) => state);

  const [receivedTyping, setReceivedTyping] = useState(false);
  const [newMessages, setNewMessages] = useState([]);
  const [lastOnline, setLastOnline] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    isFetching,
    isRefetching,
    refetch: refetchMessages,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['messages', chatId],
    queryFn: ({ pageParam = 1 }) => getChatMessages(chatId, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.prevPage * LIMIT >= lastPage.count) {
        return null;
      }

      return lastPage.prevPage + 1;
    },
  });

  const removeExtraPages = () => {
    queryClient.setQueryData(['messages', chatId], (data) => {
      return {
        pages: [data.pages[0]],
        pageParams: [data.pageParams[0]] || [1],
      };
    });
  };

  useEffect(() => {
    setNewMessages([]);

    return () => {
      removeExtraPages();
    };
  }, [chatId]);

  useEffect(() => {
    if (selectedChat.headline || selectedChat.users?.length > 2) {
      return null;
    }

    const user = selectedChat.users?.filter((user) => user.id !== auth._id);
    const time = updateTimeDifference(user[0].lastTimeOnline);

    setLastOnline(time);
  }, [auth._id, selectedChat]);

  useEffect(() => {
    if (selectedChat.unreadedMessagesCount > 0) {
      // TODO: scroll to first unreaded message
      // TODO: a mozda i ne mora ovo
      scrollToBottom();
    } else {
      if (chatContainerRef.current) {
        scrollToBottom();
      }
    }
  }, [chatId]);

  useEffect(() => {
    if (!notifications.length) return;

    const notification = notifications[notifications.length - 1];

    if (
      notification.eventName === NotificationEventType.NEW_MESSAGE &&
      chatId === notification?.chatId
    ) {
      handleNewMessageNotification(notification);
      checkSenderOnlineStatus(notification);
    } else if (
      notification.eventName === NotificationEventType.SEEN_MESSAGE &&
      chatId === notification?.chatId
    ) {
      handleSeenMessageNotification(notification);
      checkSenderOnlineStatus(notification);
    }
  }, [chatId, notifications, refetchMessages]);

  useEffect(() => {
    if (socket) {
      socket.on('typing', (data) => {
        console.log('PRIMIO TYPING');
        if (data.chatId === chatId) {
          setReceivedTyping(true);

          if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
          }

          typingTimeoutRef.current = setTimeout(() => {
            setReceivedTyping(false);
          }, 5000);
        }
      });
    }

    return () => {
      // Clean up the timer on component unmount
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [chatId, socket]);

  const checkSenderOnlineStatus = (notification) => {
    if (selectedChat.users?.length > 2) {
      return;
    }

    const senderId = JSON.parse(notification.message)?.senderId;
    const userId = JSON.parse(notification.message)?.userId;
    const user = selectedChat.users?.find((user) => user.id === senderId || user.id === userId);
    const timeDifference = getTimeDifference(user?.lastTimeOnline);
    console.log(timeDifference)
    if (timeDifference < 120) {
      return;
    }

    const currentTime = new Date().toISOString();

    const chats = queryClient.getQueryData(['chats']);

    const updatedChats = chats.map((chat) => {
      if (chat.chatId !== chatId) return chat;

      let updatedChat = JSON.parse(JSON.stringify(chat));
      let sender = updatedChat.chat.users?.find((user) => user.id === senderId || user.id === userId);

      console.log(sender)

      if (sender) {
        sender.lastTimeOnline = currentTime;
      }

      console.log(updatedChat)

      return updatedChat;
    });

    const chat = updatedChats.find(chat => chat.chatId === chatId);

    if (chat) {
      const user = chat.chat.users?.filter((user) => user.id !== auth._id);
      const time = updateTimeDifference(user[0].lastTimeOnline);
  
      setLastOnline(time);
    }

    queryClient.setQueryData(['chats'], updatedChats);

  };

  const handleNewMessageNotification = (notification) => {
    setNewMessages((oldMessages) => {
      return [JSON.parse(notification.message), ...oldMessages];
    });
    setReceivedTyping(false);

    // TODO: ili mozda umesto scroll to bottom, ukoliko nisi na
    // TODO: bottom, dodati button po sred chata dole sa brojem novih poruka
    // scrollToBottom();

    removeNotification();
  };

  const handleSeenMessageNotification = (notification) => {
    // refetchMessages();

    const message = newMessages.find((m) => m.id === notification.messageId);

    if (message) {
      // console.log('U NEW MESSAGE');
      const notificationMessage = JSON.parse(notification?.message);

      setNewMessages((old) => {
        return old.map((message) => ({
          ...message,
          ...(message.id === notification.messageId && {
            seenByIds: [
              ...(message.seenByIds || []),
              notificationMessage?.userId,
            ],
          }),
        }));
      });
    } else {
      console.log('U CACHE');

      const allMessages = queryClient.getQueryData(['messages', chatId]);

      if (!allMessages) {
        return;
      }

      const notificationMessage = JSON.parse(notification?.message);

      queryClient.setQueryData(['messages', chatId], (prevData) => {
        const updatedPages = prevData.pages.map((page) => {
          const updatedResults = page.results.map((item) =>
            item.id === notification.messageId
              ? {
                  ...item,
                  seenByIds: [
                    ...(item.seenByIds || []),
                    notificationMessage?.userId,
                  ],
                }
              : item
          );

          return {
            count: page.count,
            prevPage: page.prevPage,
            results: updatedResults,
          };
        });

        return {
          pages: updatedPages,
          pageParams: prevData.pageParams,
        };
      });
    }

    // proci kroz cache

    removeNotification();
  };

  let headline = selectedChat.headline || '';

  if (!headline) {
    selectedChat.users?.forEach((user) => {
      if (user.id !== auth._id) {
        headline += user.name;
        headline += ' ';
      }
    });
  }

  useEffect(() => {
    console.log('promenio se array');
  }, [newMessages]);

  const { mutate: mutateSendMessage } = useMutation({
    mutationFn: postMessage,
    onSuccess: (newMessage) => {
      console.log('setujem poruku...');
      setNewMessages((oldMessages) => {
        return [newMessage, ...oldMessages];
      });

      // queryClient.setQueryData(['messages', chatId], (oldData) => ({
      //   pages: [
      //     { results: [newMessage], count: 1, prevPage: 1 },
      //     ...oldData.pages,
      //   ],
      //   pageParams: [...oldData.pageParams],
      // }));

      queryClient.setQueryData(['messages', chatId], (existingMessages) => {
        // ! mozda je bolje da se poruka odmah doda u array
        // ! i da se prikaze pre nego sto se pozove ruta
        // ! i onda na osnovu onSucces, onError, da se updatuje UI poruke
        console.log(existingMessages);

        if (!existingMessages) return existingMessages;

        // const updatedCache = JSON.parse(JSON.stringify(existingMessages));
        // let { pages, pageParams } = updatedCache;
        // // let pages = [...existingMessages.pages];
        // // let pageParams = [...existingMessages.pageParams]

        // const currentPage = pages[pages.length - 1];
        // const firstPage = pages[0];

        // if (firstPage.count < 20) {
        //   // Add the message to the current page
        //   firstPage.results = [newMessage, ...firstPage.results];
        //   firstPage.count += 1;
        // } else {
        //   // Create a new page
        //   const newPage = {
        //     count: 1,
        //     prevPage: existingMessages.pages.length + 1,
        //     results: [newMessage],
        //   };

        //   pages.push(newPage);
        //   pageParams.push(existingMessages.pages.length + 1);
        // }

        // return {
        //   pages: [...pages],
        //   pageParams: [...pageParams]
        // };

        // ! mozda ako stignemo do 20 poruka po stranici,
        // ! da kreiram novi array, tj novi page
        // return {
        //   pages: [
        //     {
        //       results: [newMessage, ...existingMessages.pages[0].results],
        //       // Include other page data if available
        //       count: existingMessages.pages[0].count + 1,
        //       prevPage: existingMessages.pages[0].prevPage || undefined,
        //     },
        //     ...existingMessages.pages.slice(1),
        //   ],
        //   // Update the total count (if applicable)
        //   pageParams: [...existingMessages.pageParams],
        // };
      });

      const chats = queryClient.getQueryData(['chats']);
      const updatedChats = chats.map((chat) => ({
        ...chat,
        ...(chat.chatId === newMessage.chatId && {
          lastMessage: newMessage,
        }),
      }));

      // put chat on first plate in array
      const index = updatedChats.findIndex((chat) => chat.chatId === chatId);

      if (index !== -1) {
        updatedChats.unshift(updatedChats.splice(index, 1)[0]);
      }

      queryClient.setQueryData(['chats'], updatedChats);

      setIsTyping(false);

      setTimeout(() => {
        scrollToBottom();
      }, 50);

      reset();
    },
  });

  const scrollToBottom = () => {
    // Scroll to the bottom of the chat container
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  const onSubmit = (values) => {
    if (values.message.trim() === '') return;

    if (!values.message) {
      return;
    }

    queryClient.setQueryData(['messages', chatId], (oldData) => {
      console.log(oldData);
    });

    mutateSendMessage({
      chatId,
      blobFiles: [],
      body: {
        message: values.message,
      },
      options: {},
    });
  };

  // console.log(hasNextPage);

  function combineArrays(array1, array2) {
    const mergedArray = [...array1, ...array2];
    // console.log('arr1 ', array1)
    // console.log('arr2 ', array2)
    const idMap = new Map();
    mergedArray.forEach((obj) => {
      const { id, seenByIds, ...rest } = obj;
      if (!idMap.has(id)) {
        idMap.set(id, { id, seenByIds: new Set(seenByIds || []), ...rest });
      } else {
        const existing = idMap.get(id);

        existing.seenByIds = new Set([...existing.seenByIds, ...seenByIds]);
        // Merge other properties, prioritizing array2 values
        Object.keys(rest).forEach((key) => {
          existing[key] = obj[key] !== undefined ? obj[key] : existing[key];
        });
      }
    });

    const uniqueObjects = Array.from(idMap.values());
    return uniqueObjects.map((obj) => ({
      ...obj, // Include other properties
      id: obj.id,
      seenByIds: [...obj.seenByIds], // Convert Set back to array
    }));
  }

  const messages = useMemo(() => {
    const arr =
      data?.pages.reduce((acc, page) => {
        return [...acc, ...page.results];
      }, []) || [];

    const uniqueArray = combineArrays(newMessages, arr);

    // console.log(newMessages);
    // console.log(arr);
    // console.log(uniqueArray);

    // const messages = [...newMessages, ...arr];

    return uniqueArray;
  }, [data?.pages, newMessages]);

  // ! seenByIds je prazan niz ovde, iako gore u 382 postoji id (dodaje se id u handleSeen)
  // ! mozda proci kroz sve poruke i zameniti ih sa porukom iz newMessages
  // console.log(messages);

  const handleAudioMessage = (blob) => {
    if (!blob) {
      return console.log('there is no audio file');
    }

    console.log(blob);

    mutateSendMessage({
      chatId,
      blobFiles: [blob],
      body: {
        message: '',
      },
      options: {
        params: {
          type: 'audio',
        },
      },
    });
  };

  function sendTyping() {
    if (chatId) {
      if (socket) {
        socket.emit('typing', { chatId });
        // ! 2 puta primam typing,a ovde se salje samo jednom
        // ! to je nesto zbog onog ackTimeout, ali ako to uklonim
        // ! onda nece da salje
      }
    }
  }

  const handleSeenMessage = (msgId) => {
    const message = newMessages.find((m) => m.id === msgId);
    const loggedInUserId = auth._id;

    if (message) {
      console.log('U NEW MESSAGE');
      setNewMessages((old) => {
        return old.map((message) => ({
          ...message,
          ...(message.id === msgId && {
            seenByIds: [...(message.seenByIds || []), loggedInUserId],
          }),
        }));
      });
    } else {
      console.log('U CACHE');

      queryClient.setQueryData(['messages', chatId], (prevData) => {
        const updatedPages = prevData.pages.map((page) => {
          const updatedResults = page.results.map((item) =>
            item.id === msgId
              ? {
                  ...item,
                  seenByIds: [...item.seenByIds, loggedInUserId],
                }
              : item
          );

          return {
            count: page.count,
            prevPage: page.prevPage,
            results: updatedResults,
          };
        });

        return {
          pages: updatedPages,
          pageParams: prevData.pageParams,
        };
      });
    }
  };

  let typingTimeout = null;
  const debounce = (func, delay) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(func, delay);
  };

  const handleTyping = () => {
    // console.log('HANDLE');
    if (!isTyping) {
      // console.log('send typing...');

      setIsTyping(true);
      sendTyping();

      debounce(() => {
        // console.log('prestaje typing...');

        setIsTyping(false);
      }, 4000); // Adjust the delay here (e.g., 3000 milliseconds for 3 seconds)
    }
  };

  return (
    <div
      className={`conversation ${chatId ? 'active' : undefined}`}
      id="conversation-box"
    >
      <ConversationHeader headline={headline} lastTimeOnline={lastOnline} />

      <div
        className="conversation-main"
        id={`conversation-wrapper-ul-wrapper-${chatId}`}
        ref={chatContainerRef}
      >
        {isRefetching ? (
          <LoadingSpinner />
        ) : (
          <>
            {!(isFetching && isPending) ? (
              <>
                {messages.length ? (
                  <InfiniteScroll
                    dataLength={messages ? messages.length : 0}
                    next={() => fetchNextPage()}
                    hasMore={hasNextPage}
                    loader={
                      <div className="flex-center">
                        <MoonLoader
                          color="#555"
                          loading={isFetchingNextPage}
                          size={22}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    }
                    scrollableTarget={`conversation-wrapper-ul-wrapper-${chatId}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      overflow: 'hidden',
                    }} //To put endMessage and loader to the top.
                    inverse={true}
                    scrollThreshold="200px"
                  >
                    <div>
                      {messages && (
                        <MessagesList
                          messages={messages}
                          onSeenMessage={handleSeenMessage}
                        />
                      )}
                      {receivedTyping && (
                        // <div
                        //   style={{
                        //     display: 'flex',
                        //     alignItems: 'flex-end',
                        //     gap: '3px',
                        //   }}
                        // >
                        //   <span style={{ fontSize: '.9rem', color: '#999' }}>
                        //     typing
                        //   </span>
                        //   <PulseLoader size={3} color="#999" />
                        // </div>
                        <li className="conversation-item">
                          <div className="conversation-item-side">
                            <img
                              className="conversation-item-image"
                              src={image}
                              alt=""
                            />
                          </div>
                          <div className="conversation-item-content">
                            <div className="conversation-item-wrapper">
                              <div className="conversation-item-box">
                                <div className="conversation-item-text typing">
                                  <span>typing</span>
                                  <PulseLoader size={2} color="#fff" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </div>
                  </InfiniteScroll>
                ) : (
                  <div className="start-messaging">
                    <p style={{ fontSize: '.85rem' }}>
                      Send message to start conversation...
                    </p>
                  </div>
                )}
              </>
            ) : (
              <LoadingSpinner />
            )}
          </>
        )}
      </div>

      <div className="conversation-form">
        {/* <button type="button" className="conversation-form-button">
          <i className="ri-attachment-line"></i>
        </button> */}
        {/* <button type="button" className="conversation-form-button">
          <i className="ri-emotion-line"></i>
        </button> */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: 'flex', width: '100%' }}
        >
          <div className="conversation-form-group">
            <textarea
              className="conversation-form-input"
              autoComplete="off"
              id="message-to-send"
              rows="1"
              placeholder="Type here..."
              {...register('message')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                } else {
                  handleTyping();
                }
              }}
            ></textarea>
            {/* <AudioRecorder onFinishAudio={handleAudioMessage} /> */}
          </div>
          <button
            type="submit"
            className="conversation-form-button conversation-form-submit"
            id="buttom-send-message"
          >
            <i className="ri-send-plane-2-line"></i>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Conversation;
