import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useAuthStore from '../store/auth';

const SplashScreen = ({ isLoading, onMinimizeChat, error }) => {
  const { platform } = useAuthStore((state) => state);

  return (
    <div className="chat-container welcome-container" id="container-welcome">
      <button
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          border: 'none',
          cursor: 'pointer',
        }}
        className="close-btn"
        id="close-btn"
        onClick={onMinimizeChat}
      >
        <FontAwesomeIcon icon={faClose} />
      </button>

      <div className="get-started">
        <p>
          <span className="material-icons" id="login-logo">
            chat
          </span>
          <span className="get-started-p-title">Brrm</span>{' '}
          <span className="get-started-p-subtitle">Chat</span>
        </p>
        {isLoading && <span>Loading...</span>}
        {error && <span>{error}</span>}
      </div>
    </div>
  );
};

export default SplashScreen;
