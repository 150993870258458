import React, { useEffect, useMemo } from 'react';
import useAuthStore from '../../../store/auth';
import useChatStore from '../../../store/chat';
import OutcomingMessage from './OutcomingMessage';
import IncomingMessage from './IncomingMessage';
import { DateTime } from 'luxon';

const MessageItem = ({ message, onSeenMessage }) => {
  const arrayContainsArray = (arr, target) =>
    target.every((v) => arr.includes(v));

  const { auth } = useAuthStore((state) => state);
  const { addMessageToSeen, allSeeners } = useChatStore((state) => state);

  const sentAt = useMemo(() => {
    const parsedTimestamp = DateTime.fromISO(message.createdAt);

    // Convert to local time
    const localTime = parsedTimestamp.toLocal();

    return localTime.toFormat('HH:mm');
  }, [message.createdAt]);

  const isMe = useMemo(() => {
    // console.log('[memo isME]');
    return auth._id === message.senderId;
  }, [auth._id, message.senderId]);

  const isSeen = useMemo(() => {
    // console.log('[memo isSeen]');
    let seen = false;

    if (message.seenByIds?.length) {
      if (isMe) {
        if (
          Array.isArray(message.seenByIds) &&
          arrayContainsArray(message.seenByIds, allSeeners)
        ) {
          seen = true;
        }
      } else {
        if (arrayContainsArray(message.seenByIds || [], [auth._id])) {
          seen = true;
        }
      }
    }

    return seen;
  }, [allSeeners, auth._id, isMe, message.seenByIds]);

  // console.log('RENDER: MESSAGE ITEM');

  // if (!isMe) {
  //   if (!isSeen) {
  //     addMessageToSeen(message.id);
  //   }
  // }

  return (
    <>
      {isMe ? (
        <OutcomingMessage message={message} isSeen={isSeen} sentAt={sentAt} />
      ) : (
        <IncomingMessage
          message={message}
          isSeen={isSeen}
          sentAt={sentAt}
          onSeenMessage={onSeenMessage}
        />
      )}
    </>
  );
};

export default MessageItem;
