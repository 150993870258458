import axios from '../api/axios';

export async function authAppAccessToken(body) {
  const response = await axios.post('/appAuth', JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = response.data;

  return data;
}

export async function getAllChats() {
  const response = await axios.get('/chats', {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = response.data;

  return data;
}

export async function getChatMessages(chatId, pageParam, options = {}) {
  const limit = 20;
  console.log(pageParam);

  const headers = {
    limit,
    page: pageParam || 1,
    'Content-Type': 'application/json',
  };

  options['headers'] = headers;

  // const chatId = 'f47143f9-fd52-4fd4-8c59-6f324e2720e0';

  const response = await axios.get(`/chats/${chatId}/messages`, options);

  const data = response.data;

  return { ...data, prevPage: pageParam };
}

export async function postMessage(data) {
  const { chatId, blobFiles = [], body = {}, options = {} } = data;

  const bodyFormData = new FormData();
  blobFiles.forEach((blobFile) => {
    console.log(blobFile)
    bodyFormData.append('file', blobFile);
  });

  Object.keys(body).forEach((key) => {
    bodyFormData.append(key, body[key]);
  });

  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  options['headers'] = headers;
  console.log(blobFiles);
  console.log(bodyFormData);

  const response = await axios.post(
    `/chats/${chatId}/messages`,
    bodyFormData,
    options
  );

  return response.data;
}

export async function subscribeDevice(body) {
  const response = await axios.post('/devices', JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = response.data;

  return data;
}

export async function sendSeenStatusMessage(data) {
  const { chatId, messageId } = data;
  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await axios.patch(
    `/chats/${chatId}/messages/${messageId}/seen`,
    {},
    {
      headers,
    }
  );

  return response.data;
}

export async function getPhonebook(userId) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await axios.get(`/users/${userId}/users`, {
    headers,
  });

  const data = response.data;

  return data;
}

export async function addChat(body) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await axios.post('/chats', JSON.stringify(body), {
    headers,
  });

  const data = response.data;

  return data;
}
