import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOM from 'react-dom';
import classes from './Modal.module.css';

export const Backdrop = (props) => {
  const backdropColor =
    props.backdropColor === 'white' ? classes.white_bg : null;

  return (
    <div
      className={`${classes.backdrop} ${backdropColor}`}
      onClick={props.onClose}
    ></div>
  );
};

const titleStyle = {
  margin: '0',
  fontSize: '1.5rem',
  fontWeight: '600',
};

const ModalOverlay = ({
  onClose,
  children,
  exit = true,
  title = null,
  className,
}) => {
  const _classes = className ? classes[className] : classes['modal'];

  return (
    <div className={_classes}>
      <div className={classes.scroll}>
        <div
          className={`${classes.modal__header} ${
            !title ? 'flex-end' : 'flex-between'
          }`}
        >
          {title && <p style={titleStyle}>{title}</p>}
          {exit && (
            <FontAwesomeIcon
              icon={faClose}
              onClick={onClose}
              style={{ fontSize: '1.4rem', cursor: 'pointer' }}
            />
          )}
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

const Modal = (props) => {
  const portalElement = document.getElementById('overlays');

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={props.title}
          exit={props.exit}
          onClose={props.onClose}
          className={props.className}
        >
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
