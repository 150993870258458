import React from 'react';
import image from '../../../assets/img/profile_default.jpg';
import useChatStore from '../../../store/chat';

const ConversationHeader = ({ headline, lastTimeOnline }) => {
  const { setChat } = useChatStore((state) => state);

  return (
    <div className="conversation-top">
      <button
        type="button"
        className="conversation-back"
        id="conversation-back-button"
        onClick={() => setChat(null)}
      >
        <i className="ri-arrow-left-line"></i>
      </button>
      <div className="conversation-user" id="conversation-user">
        <img className="conversation-user-image" src={image} alt="" />
        <div>
          <div className="conversation-user-name">{headline}</div>
          {lastTimeOnline && (
            <div className="conversation-user-status online">
              {lastTimeOnline}
            </div>
          )}
        </div>
      </div>
      {/* <div className="conversation-buttons">
        <button type="button">
          <i className="ri-phone-fill"></i>
        </button>
        <button type="button">
          <i className="ri-vidicon-line"></i>
        </button>
        <button type="button">
          <i className="ri-information-line"></i>
        </button>
      </div> */}
    </div>
  );
};

export default ConversationHeader;
